import { addApiKey } from '../../../../server/src/controllers/apiKey/addApiKey';
<template>
    <div class="add-api-key">
        <h1>Create New API Key</h1>
        <form @submit.prevent="createApiKeyHandler">
            <div class="form-group">
                <label for="name">API Key Description:</label>
                <input type="text" id="name" v-model="description" required />
            </div>
            <button type="submit">Create</button>
        </form>
    </div>
</template>

<script>
import { addApiKey } from '@/services/ApiKeyService';
export default {
    data() {
        return {
            description: ''
        };
    },
    methods: {
        async createApiKeyHandler() {
            if (!this.description) return;
            await addApiKey(this.description);
            this.$router.push('/apiKeys');
        }
    }
};
</script>

<style scoped>
.add-api-key {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>